import { Flex } from '@stars-ecom/shared-atoms-ui'
import { AccountLayout, AccountPreferences } from '@stars-ecom/shared-organisms-ui'
import React from 'react'
import { Helmet } from 'react-helmet'

import { siteFormatter } from '../utils/metaTitleFormatter'

const AccountPreferencesPage = () => {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      pb="15px"
      backgroundColor="#f6f3f1"
      w="100%">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={`Mes préférences - ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}
        />
        <title>{`Mes préférences - ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <AccountLayout>
        <AccountPreferences />
      </AccountLayout>
    </Flex>
  )
}

AccountPreferencesPage.pageType = 'account'

export default AccountPreferencesPage
